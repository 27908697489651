import {OrderInterface} from '@interfaces/order';
import {PaymentProviderResponse} from '@interfaces/payment-provider';

export const UserCartUUIDCookieKey = 'user_cart_uuid';

export interface UserCartStateInterface {
  cartItems: OrderInterface;
  paymentMethods: {[key: string]: Record<string, any>};
  deliveryMethods: {[key: string]: Record<string, any>};
  paymentProviders: PaymentProviderResponse;
  itemsCount: number;
  loading: boolean;
  addItemLoading: boolean;
  updateOrderLoading: boolean;
}
export const userCartState: UserCartStateInterface = {
  cartItems: {} as unknown as OrderInterface,
  paymentProviders: {} as unknown as PaymentProviderResponse,
  paymentMethods: {},
  deliveryMethods: {},
  itemsCount: 0,
  loading: false,
  addItemLoading: false,
  updateOrderLoading: false,
};
