import NextLink from 'next/link';
import {useRouter} from 'next/router';
import styles from './style.module.css';
import {FocusEventHandler, MouseEventHandler, ReactNode} from 'react';

interface LinkProps {
  classNames?: string;
  as?: URL | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  href?: string | undefined;
  locale?: string | undefined;
  target?: string | '_blank' | '_self' | '_top' | '_parent' | undefined;
  variant?: 'nextLink' | 'buttonLink' | 'link' | undefined;
  children: ReactNode;
}
export default function Link(props: LinkProps) {
  const router = useRouter();
  const currentLocale = router.locale!;
  const {
    children,
    onClick,
    classNames = '',
    as,
    href = '/',
    locale = currentLocale,
    variant = 'nextLink',
    target,
    ...rest
  } = props;

  return (() => {
    switch (variant) {
      case 'nextLink':
        return (
          <NextLink
            href={href}
            locale={locale || currentLocale}
            target={target}
            as={as || ''}
            {...rest}
            onClick={event => {
              if (onClick) onClick(event);
            }}
            className={`${classNames}`}
          >
            {children}
          </NextLink>
        );
      case 'buttonLink':
        return (
          <button
            {...rest}
            onClick={onClick}
            className={`${styles.buttonLink} ${classNames}`}
            type={'button'}
          >
            {children}
          </button>
        );
      default:
        return (
          <NextLink
            href={href}
            locale={locale || currentLocale}
            as={as || ''}
            {...rest}
            onClick={event => {
              if (onClick) onClick(event);
            }}
            className={`${classNames}`}
          >
            {children}
          </NextLink>
        );
    }
  })();
}
