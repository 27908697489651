import {createContext, useReducer, useState} from 'react';

import {userCartReducer} from '@contexts/reducers';

import {
  userCartState,
  UserCartStateInterface,
  UserCartUUIDCookieKey,
} from '@contexts/states';
import {UserCartActionTypes as Actions} from '@contexts/actions';
import {getCookie, setCookies, removeCookies} from 'cookies-next';
import axios from '../../lib/axios';
import {useRouter} from 'next/router';
import OverlayCheckoutMessage from '@components/CheckoutComponents/OverlayCheckoutMessage';
import {toast} from 'react-toastify';
import {useRequest} from '@hooks/use-request';
import {ContextProps} from '@contexts/index';
import {OrderInterface} from '@interfaces/order';
import {PaymentProviderResponse} from '@interfaces/payment-provider';
import {APIRequestConfig} from '@enums/request-config';
import {useTranslation} from 'next-i18next';

interface ContextInterface {
  state: UserCartStateInterface;
  loading: boolean;
  addItemToCart: (payload: {items: RequestItems}) => void;
  fetchUserCartItems: () => void;
  updateUserOrder: (
    payload: unknown,
    type?: string,
    successCallback?: () => void
  ) => Promise<void>;
  getPaymentAndDeliveryMethods: (
    params?: Record<string, string | undefined>
  ) => Promise<void>;
  deleteCartItem: (orderRowUUID: string) => void;
  sendUserOrder: (data: unknown, onComplete?: () => void) => void;
  updateCartItemQuantity: (
    orderRowUUID: string,
    quantity: number
  ) => Promise<void>;
  applyDiscountCode: (discountCode: string) => void;
  setDeliveryInfo: (deliveryInfo: Record<string, string>) => void;
  removeDiscountCode: () => void;
}
export const UserCartContext = createContext<ContextInterface>(
  {} as unknown as ContextInterface
);

interface RequestItems {
  product_id: number;
  type?: 'product';
  quantity: number;
  key_value_pairs: string;
  customer_visible_comment?: string;
  linked_products: {
    product_id: number;
    type?: 'product_accessories';
    quantity: number;
  }[];
}

export function UserCartContextProvider({children}: ContextProps) {
  const {t} = useTranslation('common');
  const [state, dispatch] = useReducer(userCartReducer, userCartState);
  const router = useRouter();
  const locale = router.locale;
  const {loading, sendRequest} = useRequest(locale);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errors, setErrors] = useState('');

  const getOrderUUID = (showNotification = true) => {
    const cart_uuid = getCookie(UserCartUUIDCookieKey);
    if (cart_uuid || typeof cart_uuid === 'string') return cart_uuid;
    if (showNotification) toast.error(t<string>('cart_not_found'));
    return null;
  };

  const addItemToCartRequest = async (
    items: RequestItems,
    cart_uuid: string
  ) => {
    await sendRequest({
      url: `orders/orderrow/${cart_uuid}`,
      method: 'post',
      body: {...items},
      requiresAuth: true,
      onSuccess: async () => {
        const quantity = items.quantity;
        dispatch({type: Actions.ITEMS_ADDED_TO_CART, payload: {quantity}});
        toast.success(t<string>('cart_item_added'), {
          position: 'bottom-right',
          autoClose: 2000,
        });
        await fetchUserCartItems();
      },
      onError: errors => {
        toast.error(errors[0].message);
      },
    });
  };

  const createOrderRequest = (): Promise<OrderInterface> => {
    return new Promise((resolve, reject) => {
      sendRequest({
        url: 'orders/order',
        method: 'post',
        body: {},
        requiresAuth: true,
        onSuccess: response => {
          resolve(response.data);
        },
        onError: errors => {
          reject(errors);
        },
      });
    });
  };

  const addItemToCart = async (payload: {items: RequestItems}) => {
    dispatch({type: Actions.ADDING_ITEM_TO_CART});
    let cart_uuid = getOrderUUID(false);
    const {items} = payload;
    if (typeof cart_uuid !== 'string') {
      const order_details = await createOrderRequest();
      const {uuid} = order_details;
      cart_uuid = uuid;
      setCookies(UserCartUUIDCookieKey, uuid);
    }
    await addItemToCartRequest(items, cart_uuid);
  };

  const fetchUserCartItems = async () => {
    dispatch({type: Actions.FETCH_USER_CART});
    const cart_uuid = getCookie(UserCartUUIDCookieKey);
    let cartItems: UserCartStateInterface['cartItems'] =
      {} as unknown as UserCartStateInterface['cartItems'];
    if (cart_uuid && typeof cart_uuid === 'string') {
      await sendRequest({
        url: `orders/order/${cart_uuid}`,
        method: 'GET',
        onSuccess: response => {
          cartItems = response.data;
          dispatch({type: Actions.USER_CART_FETCHED, payload: {cartItems}});
        },
        onError: () => {
          removeCookies(UserCartUUIDCookieKey);
          dispatch({type: Actions.USER_CART_FETCHED, payload: {cartItems}});
        },
      });
    }
  };

  const fetchPaymentAndDeliveryOptions = async (
    params?: Record<string, string | undefined>
  ) => {
    const cart_uuid = getOrderUUID(false);
    if (cart_uuid) {
      await sendRequest({
        url: `orders/${cart_uuid}/paymentanddeliverymethods`,
        method: 'GET',
        requiresAuth: true,
        params,
        onSuccess: response => {
          const payload = response.data; //{payment_methods:{},delivery_options:{}}
          dispatch({type: Actions.UPDATE_DELIVERY_PAYMENT_OPTIONS, payload});
        },
      });
    }
  };

  const updateUserOrder = async (
    payload: unknown,
    type = 'normal',
    successCallback?: () => void
  ) => {
    dispatch({type: Actions.UPDATE_USER_ORDER});
    const cart_uuid = getOrderUUID();
    const getUpdateURL = (type: string) => {
      switch (type) {
        case 'delivery':
          return `orders/order/${cart_uuid}/delivery`;
        case 'invoice':
          return `orders/order/${cart_uuid}/invoice`;
        default:
          return `orders/order/${cart_uuid}`;
      }
    };
    if (cart_uuid) {
      await sendRequest({
        url: getUpdateURL(type),
        method: 'patch',
        body: payload,
        requiresAuth: true,
        // eslint-disable-next-line no-unused-vars
        onSuccess: async response => {
          try {
            await fetchUserCartItems();
            dispatch({type: Actions.USER_ORDER_UPDATED});
            if (successCallback) {
              successCallback();
            }
          } catch (e) {
            toast.error('Error updating cart!');
          }
        },
        onError: (errors, statusCode) => {
          toast.error(errors[0].message);
          if (statusCode && statusCode === 403) {
            //Remove the order uuid from the cookie
            removeCookies(UserCartUUIDCookieKey);
            //Refresh the checkout page
            router.reload();
          }
          dispatch({type: Actions.USER_ORDER_UPDATED});
        },
      });
    }
  };

  const sendUserOrder = async (data: unknown, onComplete?: () => void) => {
    dispatch({type: Actions.UPDATE_USER_ORDER});
    setErrors('');
    const cart_uuid = getOrderUUID();
    if (cart_uuid) {
      await sendRequest<{
        data: PaymentProviderResponse;
      }>({
        url: `orders/sendorder/${cart_uuid}`,
        method: 'patch',
        body: data,
        requiresAuth: true,
        onSuccess: response => {
          removeCookies(UserCartUUIDCookieKey);
          if (response.data.payment_method !== 'Lasku') {
            const paymentProviders = response.data;
            dispatch({
              type: Actions.ADD_PAYMENT_PROVIDERS,
              payload: {paymentProviders},
            });
          } else {
            setShowConfirmation(true);
          }
          if (onComplete) onComplete();
        },
        onError: errors => {
          toast.error(errors[0].message);
        },
      });
    }
  };

  const deleteCartItem = async (orderRowUUID: string) => {
    const cart_uuid = getOrderUUID();
    if (cart_uuid) {
      await sendRequest({
        url: `orders/orderrow/${cart_uuid}/${orderRowUUID}`,
        method: 'delete',
        requiresAuth: true,
        onSuccess: async () => {
          await fetchUserCartItems();
        },
        onError: errors => {
          toast.error(errors[0].message);
        },
      });
    }
  };

  const applyDiscountCode = async (discountCode: string) => {
    const order_uuid = getOrderUUID();
    if (order_uuid) {
      await sendRequest({
        url: 'orders/discounts',
        method: 'post',
        requiresAuth: true,
        body: {
          order_uuid,
          discount_code: discountCode,
        },
        onSuccess: async () => {
          await fetchUserCartItems();
        },
        onError: errors => {
          toast.error(errors[0].message);
        },
      });
    }
  };

  const removeDiscountCode = async () => {
    const order_uuid = getOrderUUID();
    if (order_uuid) {
      await sendRequest({
        url: 'orders/discounts',
        method: 'delete',
        requiresAuth: true,
        body: {
          order_uuid,
        },
        onSuccess: async () => {
          await fetchUserCartItems();
        },
        onError: errors => {
          toast.error(errors[0].message);
        },
      });
    }
  };

  const updateCartItemQuantity = async (
    orderRowUUID: string,
    quantity: number
  ): Promise<void> => {
    const cart_uuid = getOrderUUID();
    if (cart_uuid) {
      await axios.patch(
        `orders/orderrow/${cart_uuid}/${orderRowUUID}`,
        {
          quantity,
        },
        {
          headers: {
            [APIRequestConfig.X_LOCALE]: locale!,
          },
        }
      );
      await fetchUserCartItems();
    }
  };
  const setDeliveryInfo = async (
    deliveryInfo: Record<string, string>
  ): Promise<void> => {
    dispatch({
      type: Actions.SET_USER_DELIVERY_INFO,
      payload: {
        deliveryInfo,
      },
    });
  };
  return (
    <UserCartContext.Provider
      value={{
        state,
        loading,
        addItemToCart,
        fetchUserCartItems,
        updateUserOrder,
        deleteCartItem,
        sendUserOrder,
        updateCartItemQuantity,
        applyDiscountCode,
        removeDiscountCode,
        getPaymentAndDeliveryMethods: fetchPaymentAndDeliveryOptions,
        setDeliveryInfo,
      }}
    >
      <OverlayCheckoutMessage
        payment={{status: errors ? 'failed' : 'successful'}}
        order={state.cartItems!}
        show={showConfirmation}
        successCallback={async () => {
          setShowConfirmation(false);
          await router.replace('/');
        }}
      />
      {children}
    </UserCartContext.Provider>
  );
}
