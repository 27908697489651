import {
  ProductDisplayAccessory,
  ProductDisplayActions,
  ProductDisplayActionTypes as Actions,
} from '@contexts/actions';
import {
  ProductDisplayStateInterface,
  productDisplayState,
} from '@contexts/states';

export function productDisplayReducer(
  state = productDisplayState,
  action: ProductDisplayActions
): ProductDisplayStateInterface {
  switch (action.type) {
    case Actions.PRODUCT_FETCHED:
      const {accessories, ...rest} = action.payload;
      return {
        ...state,
        product: {
          ...rest,
        },
        ...getProductAccessories(accessories || {}, state.linked_products),
        load_product_prices: true,
      };

    case Actions.GET_PRODUCT:
      return {
        ...state,
        product: undefined,
        accessories: {},
        load_product_prices: false,
      };
    case Actions.LINKED_PRODUCT_CHANGED:
      const {accessory_id, product_group_id} = action.payload;
      const selectedAccessory = state.accessories[product_group_id]?.find(
        item => Number(item.id) === Number(accessory_id)
      );
      if (selectedAccessory) {
        const tempLinkedProducts = state.linked_products;
        tempLinkedProducts[product_group_id] = {...selectedAccessory};
        return {
          ...state,
          linked_products: tempLinkedProducts,
          load_product_prices: true,
        };
      }
      return {...state, load_product_prices: true};
    case Actions.SET_PRODUCT_PRICES:
      const {
        total_price_vat_excluded,
        total_price_vat_included,
        discount_unit_price_vat_excluded,
        discount_unit_price_vat_included,
        total_discount_price_vat_included,
        total_discount_price_vat_excluded,
        unit_price_vat_excluded,
        unit_price_vat_included,
        vat_percentage,
        vat_text,
      } = action.payload ?? {};
      const product = {
        ...state.product!,
        discount_unit_price_vat_excluded,
        discount_unit_price_vat_included,
        unit_price_vat_excluded,
        unit_price_vat_included,
        vat_percentage,
        vat_text,
      };
      const prev_state_linked_products = state.linked_products;
      const linked_products: ProductDisplayStateInterface['linked_products'] =
        {};
      Object.keys(prev_state_linked_products).map(product_group_id => {
        const linked_product = prev_state_linked_products[product_group_id];
        if (linked_product) {
          const res_linked_product = action.payload.linked_products?.find(
            prod => prod.product_id === linked_product.id
          );
          if (res_linked_product) {
            const {
              unit_price_vat_excluded,
              unit_price_vat_included,
              discount_unit_price_vat_included,
              discount_unit_price_vat_excluded,
              total_discount_price_vat_included,
              total_discount_price_vat_excluded,
            } = res_linked_product;
            linked_products[product_group_id] = {
              ...linked_product,
              discount_unit_price_vat_included,
              discount_unit_price_vat_excluded,
              unit_price_vat_excluded,
              unit_price_vat_included,
              total_discount_price_vat_included,
              total_discount_price_vat_excluded,
            } as ProductDisplayAccessory;
          }
        }
      });
      return {
        ...state,
        total_price_vat_excluded,
        total_discount_price_vat_excluded,
        total_discount_price_vat_included,
        total_price_vat_included,
        product,
        linked_products,
        load_product_prices: false,
      };
    case Actions.LOAD_PRODUCT_PRICES:
      return {...state, load_product_prices: true};

    case Actions.SET_PRODUCT_DIMENSIONS:
      return {...state, dimensions: {...state.dimensions, ...action.payload}};
    case Actions.SET_PRODUCT_QUANTITY:
      return {
        ...state,
        quantity: action.quantity || 1,
        load_product_prices: true,
      };
    case Actions.SET_KEY_VALUE_PAIRS:
      let key_value_pairs;
      try {
        key_value_pairs = JSON.stringify({
          ...JSON.parse(state.key_value_pairs),
          ...action.payload,
        });
      } catch (e) {
        key_value_pairs = JSON.stringify(key_value_pairs);
      }
      return {
        ...state,
        key_value_pairs,
      };
    default: {
      return state;
    }
  }
}

const getProductAccessories = (
  accessories: ProductDisplayStateInterface['accessories'],
  previousStateLinkedProducts: ProductDisplayStateInterface['linked_products']
) => {
  const linked_products: ProductDisplayStateInterface['linked_products'] = {};
  const sortedAccessories: ProductDisplayStateInterface['accessories'] = {};
  Object.keys(accessories).map(product_group_id => {
    const previousAddedAccessory =
      previousStateLinkedProducts[product_group_id];
    accessories[product_group_id] = accessories[product_group_id].map(p => {
      if (!p.unit_price_vat_excluded) {
        p.unit_price_vat_excluded = 0;
      }
      return p;
    });
    sortedAccessories[product_group_id] = accessories[product_group_id].sort(
      (a, b) => {
        return (
          (a?.unit_price_vat_excluded || 0) - (b?.unit_price_vat_excluded || 0)
        );
      }
    );
    if (previousAddedAccessory) {
      linked_products[product_group_id] = {...previousAddedAccessory};
    } else {
      const productAccessory = sortedAccessories[product_group_id][0];
      linked_products[product_group_id] = {...productAccessory};
    }
  });

  return {accessories: sortedAccessories, linked_products};
};
