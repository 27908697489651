import {LocaleEnum} from '@enums/locale';
import {APIRequestConfig} from '@enums/request-config';
import axios from 'axios';
import {getCookie} from 'cookies-next';
import {getSession, signOut} from 'next-auth/react';
import {toast} from 'react-toastify';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-API-KEY': process.env.NEXT_PUBLIC_API_KEY || '',
    [APIRequestConfig.X_LOCALE]:
      (getCookie('NEXT_LOCALE') as string) || LocaleEnum.FI,
  },
});

export const sessionAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-API-KEY': process.env.NEXT_PUBLIC_API_KEY || '',
    [APIRequestConfig.X_LOCALE]:
      (getCookie('NEXT_LOCALE') as string) || LocaleEnum.FI,
  },
});
sessionAxios.interceptors.request.use(async config => {
  const session = await getSession();
  config.headers!['Authorization'] = `Bearer ${session?.accessToken}`;
  return config;
});

sessionAxios.interceptors.response.use(
  async response => {
    const authorizationInvalid = !!response.headers['x-authorization-invalid'];
    if (authorizationInvalid) {
      const session = await getSession();
      if (session?.accessToken) {
        await signOut({redirect: false});
        toast.success('Your login session has expired! Please login again', {
          autoClose: 5000,
        });
      }
    }
    return response;
  },
  async error => {
    throw error;
  }
);
export default instance;
