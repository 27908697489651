import {ProductInterface} from '@interfaces/product';
import {ProductDisplayStateInterface} from '@contexts/states';
import {ImageInterface} from '@interfaces/images';

export enum ProductDisplayActionTypes {
  GET_PRODUCT,
  PRODUCT_FETCHED,
  LOAD_PRODUCT_PRICES,
  SET_PRODUCT_DIMENSIONS,
  SET_PRODUCT_QUANTITY,
  SET_KEY_VALUE_PAIRS,
  LINKED_PRODUCT_CHANGED,
  SET_PRODUCT_PRICES,
}

export interface ProductPrices {
  id: number;
  product_id: number;
  item_code: string;
  quantity: number;
  vat_text: string;
  vat_percentage: number;
  discount_unit_price_vat_included: number;
  discount_unit_price_vat_excluded: number;
  unit_price_vat_excluded: number;
  unit_price_vat_included: number;
  total_price_vat_excluded: number;
  total_price_vat_included: number;
  total_discount_price_vat_excluded: number;
  total_discount_price_vat_included: number;
}

export interface ProductDisplayAccessory extends ProductInterface {
  unit_price_vat_excluded: number;
  unit_price_vat_included: number;
  discount_unit_price_vat_included: number;
  discount_unit_price_vat_excluded: number;
  total_discount_price_vat_excluded: number;
  total_discount_price_vat_included: number;
  images: ImageInterface[];
}

export interface FetchProduct {
  type: ProductDisplayActionTypes.GET_PRODUCT;
}

export interface SetProductQuantity {
  type: ProductDisplayActionTypes.SET_PRODUCT_QUANTITY;
  quantity: number;
}

export interface LinkedProductChanged {
  type: ProductDisplayActionTypes.LINKED_PRODUCT_CHANGED;
  payload: {accessory_id: number; product_group_id: string};
}

export interface SetProduct {
  type: ProductDisplayActionTypes.PRODUCT_FETCHED;
  payload: ProductInterface &
    ProductPrices & {
      accessories?: Record<string, ProductDisplayAccessory[]>;
    };
}

export interface SetProductPrices {
  type: ProductDisplayActionTypes.SET_PRODUCT_PRICES;
  payload: ProductPrices & {linked_products?: ProductPrices[]};
}

export interface LoadProductPrices {
  type: ProductDisplayActionTypes.LOAD_PRODUCT_PRICES;
}

export interface SetProductDimensions {
  type: ProductDisplayActionTypes.SET_PRODUCT_DIMENSIONS;
  payload: Partial<ProductDisplayStateInterface['dimensions']>;
}

export interface SetKeyValuePairs {
  type: ProductDisplayActionTypes.SET_KEY_VALUE_PAIRS;
  payload: Record<string, string>;
}

export type ProductDisplayActions =
  | FetchProduct
  | SetProductQuantity
  | LinkedProductChanged
  | SetProduct
  | SetProductPrices
  | LoadProductPrices
  | SetProductDimensions
  | SetKeyValuePairs;
