import {createContext, Dispatch, useReducer} from 'react';

import {ContextProps} from '@contexts/index';
import modalReducer from '@contexts/reducers/modal';
import {modalState, ModalStateInterface} from '@contexts/states';
import {ModalActions} from '@contexts/actions';

interface ModalContextInterface {
  state: ModalStateInterface;
  dispatch: Dispatch<ModalActions>;
}
export const ModalContext = createContext<ModalContextInterface>(
  {} as unknown as ModalContextInterface
);

export function ModalContextProvider({children}: ContextProps) {
  const [state, dispatch] = useReducer(modalReducer, modalState);
  const value = {state, dispatch};
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
