import styles from './style.module.css';

import {useEffect} from 'react';
import CheckoutMessageBox from '../CheckoutMessageBox';
import {Modal} from 'reactstrap';
import {OrderInterface} from '@interfaces/order';
import {ComponentWithChildrenProps} from '@interfaces/component';

interface OverlayCheckoutMessageProps extends ComponentWithChildrenProps {
  show: boolean;
  order: OrderInterface;
  payment: {status: string};
  successCallback: () => void;
}

export default function OverlayCheckoutMessage(
  props: OverlayCheckoutMessageProps
) {
  const {show, order, payment, successCallback} = props;
  useEffect(() => {
    if (show) {
      document.querySelector('body')!.style.overflow = 'hidden';
    } else {
      document.querySelector('body')!.style.overflow = 'scroll';
    }
  }, [show]);

  return (
    <Modal
      isOpen={show}
      size={'sm'}
      centered={true}
      className={styles.checkoutMessageModalContent}
    >
      <CheckoutMessageBox
        payment={payment}
        order={order}
        successCallback={successCallback}
      />
    </Modal>
  );
}
