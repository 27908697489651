import slugify from 'slugify';
import {setCookies, getCookie} from 'cookies-next';
import {checkoutCallbackUrls} from '@constants/shopping-cart';
import {LocaleEnum} from '@enums/locale';

export const reloadSession = () => {
  const event = new Event('visibilitychange');
  document.dispatchEvent(event);
};

export const ReqRegUIDSCookieKey = 'require_reg_uids';
export function slugifyUrl(text: string, locale = 'fi') {
  return slugify(text, {
    lower: true,
    strict: true,
    locale: locale,
    trim: true,
  });
}

export const sumPrice = (collection: any, key: string) => {
  let total = 0;
  if (typeof collection === 'object') {
    Object.keys(collection).map((index: string) => {
      const item = collection[index];
      total += Number(key ? item[key] : item);
    });
    return total;
  }
  return collection.reduce((item: Record<string, any>, total: number) =>
    total + key ? item[key] : item
  );
};

// eslint-disable-next-line no-unused-vars
export const toThousand = (number: any, decimal = 2) => {
  if (isNaN(number)) {
    if (!number) {
      return '0.00';
    }

    return Number(number.replace(/,/g, '')).toLocaleString(navigator.language, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  return (number * 1).toLocaleString('fi-FI', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export function setRequireRegistrationUIDS(
  value: string,
  options: object = {}
) {
  const valueArray = value.split(',');
  const cookieValue = getCookie(ReqRegUIDSCookieKey, options);
  const cookieOptions = {...options, maxAge: 60 * 60 * 24 * 365};
  if (cookieValue) {
    if (typeof cookieValue === 'string') {
      const cookieValueArray = cookieValue.split(',');
      valueArray.map(value => {
        if (!cookieValueArray.includes(value)) {
          cookieValueArray.push(value);
        }
      });
      setCookies(
        ReqRegUIDSCookieKey,
        cookieValueArray.join(','),
        cookieOptions
      );
    }
  } else {
    setCookies(ReqRegUIDSCookieKey, value, cookieOptions);
  }
}

export function getRequireRegistrationUIDS(options: object = {}) {
  return getCookie(ReqRegUIDSCookieKey, options) ?? '';
}

export const paymentRedirectUrls = (locale: LocaleEnum) => {
  const {success, cancel} = checkoutCallbackUrls[locale];
  return {
    success: process.env.NEXT_PUBLIC_APP_URL + success,
    cancel: process.env.NEXT_PUBLIC_APP_URL + cancel,
  };
};

export const getVATTextKey = (vatText: string) => {
  switch (true) {
    case vatText.toLowerCase().includes('intra community'):
      return 'vat_texts.intra_community_vat_text';
    default:
      return '';
  }
};
