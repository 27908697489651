export enum ModalActionTypes {
  TOGGLE_LOGIN_MODAL,
  TOGGLE_FORGOT_PASSWORD_MODAL,
}

export interface ToggleLoginModal {
  type: ModalActionTypes.TOGGLE_LOGIN_MODAL;
}

export interface ToggleForgotPasswordModal {
  type: ModalActionTypes.TOGGLE_FORGOT_PASSWORD_MODAL;
}

export type ModalActions = ToggleLoginModal | ToggleForgotPasswordModal;
