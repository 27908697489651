export const CheckoutCountries = [
  {
    code: 'FI',
    name_fi: 'Suomi',
    name_en: 'Finland',
    name_se: 'Finland',
  },
  {
    code: 'SE',
    name_fi: 'Ruotsi',
    name_en: 'Sweden',
    name_se: 'Sverige',
  },
  {
    code: 'NO',
    name_fi: 'Norja',
    name_en: 'Norway',
    name_se: 'Norge',
  },
  {
    code: 'DK',
    name_fi: 'Tanska',
    name_en: 'Denmark',
    name_se: 'Danmark',
  },
  {
    code: 'NL',
    name_fi: 'Alankomaat',
    name_en: 'Netherlands',
    name_se: 'Nederländerna',
  },
];

export const checkoutCallbackUrls = {
  fi: {
    success: '/maksu/suoritettu',
    cancel: '/maksu/peruttu',
  },
  en: {
    success: '/en/payment/success',
    cancel: '/en/payment/cancel',
  },
  se: {
    success: '/se/betalning/ringtillbaka',
    cancel: '/se/betalning/framgång',
  },
};

export const canOrderCountry = ['fi', 'se', 'sv'];

export const quotationDeliveryPostalCodes = [
  21740, 22100, 22101, 22120, 22130, 22140, 22150, 22160, 22220, 22240, 22270,
  22271, 22310, 22320, 22330, 22340, 22410, 22411, 22430, 22520, 22530, 22550,
  22610, 22630, 22710, 22720, 22730, 22810, 22820, 22830, 22840, 22910, 22920,
  22930, 22940, 22950, 21610, 21630, 21650, 21660, 21670, 21680, 21710, 21720,
  21740, 21750, 21760, 21770, 23390,
];
