import styles from './style.module.css';
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '@components/Forms/Button';

import {toThousand} from '@lib/utils';
import {useTranslation} from 'next-i18next';

import {OrderInterface} from '@interfaces/order';
import {OrderTypeEnum} from '@enums/order';
import {ComponentWithChildrenProps} from '@interfaces/component';
import {isPaytrailPayment} from '@lib/order';

interface CheckoutMessageBoxProps extends ComponentWithChildrenProps {
  payment: {status: string};
  order: Omit<OrderInterface, 'rows'>;
  isConfirmationPage?: boolean;
  successCallback: () => void;
}

export default function CheckoutMessageBox(props: CheckoutMessageBoxProps) {
  const {payment, order, isConfirmationPage, successCallback} = props;
  const {t} = useTranslation('shopping-cart');

  return (
    <div className={styles.cardContainer}>
      {
        <div className={styles.notificationBox}>
          <div
            className={`${styles.boxHeader} ${
              isConfirmationPage && styles.confirmationBoxHeader
            } ${payment.status === 'successful' && styles.success}
                          ${payment.status === 'failed' && styles.fail} `}
          >
            {payment.status === 'successful' && (
              <>
                <h4>
                  <FontAwesomeIcon size={'2x'} icon={faCheckCircle} />{' '}
                </h4>
                <h4>
                  {isPaytrailPayment(order.payment_method)
                    ? t<string>('payment_process.payment_successful', {
                        ns: 'shopping-cart',
                      })
                    : order.type === OrderTypeEnum.Quotations
                    ? t<string>('payment_process.quotation_successful', {
                        ns: 'shopping-cart',
                      })
                    : t<string>('payment_process.order_successful', {
                        ns: 'shopping-cart',
                      })}
                </h4>
              </>
            )}
            {payment.status === 'failed' && (
              <>
                <h4>
                  <FontAwesomeIcon size={'2x'} icon={faTimesCircle} />{' '}
                </h4>
                <h4>
                  {t<string>('payment_process.payment_failed', {
                    ns: 'shopping-cart',
                  })}
                </h4>
              </>
            )}
            <h6>
              {order.type === OrderTypeEnum.Quotations
                ? t<string>('payment_process.quotation_success_message', {
                    ns: 'shopping-cart',
                  })
                : t<string>('payment_process.order_success_message', {
                    ns: 'shopping-cart',
                  })}
            </h6>
          </div>
          <div className={styles.boxBody}>
            <div className={styles.boxRow}>
              <p className={styles.label}>
                {order.type === OrderTypeEnum.Quotations
                  ? t<string>('payment_process.quotation_reference', {
                      ns: 'shopping-cart',
                    })
                  : t<string>('payment_process.order_id', {
                      ns: 'shopping-cart',
                    })}
              </p>
              <p className={styles.value}>#{order.id} </p>
            </div>
            <div className={styles.boxRow}>
              <p className={styles.label}>
                {t<string>('payment_process.delivery_fee', {
                  ns: 'shopping-cart',
                })}
              </p>
              <p className={styles.value}>
                {toThousand(order.delivery_method_price_vat_included)} €
              </p>
            </div>
            <div className={styles.boxRow}>
              <p className={styles.label}>
                {t<string>('payment_process.payment_fee', {
                  ns: 'shopping-cart',
                })}
              </p>
              <p className={styles.value}>
                {toThousand(order.payment_method_price_vat_included)} €
              </p>
            </div>
            <div className={styles.boxRow}>
              <p className={styles.label}>
                {t<string>('payment_process.total_price', {
                  ns: 'shopping-cart',
                })}
              </p>
              <p className={styles.value}>
                {toThousand(order.total_price_vat_included)} €
              </p>
            </div>
          </div>
          <Button onClick={successCallback} block={true}>
            {t<string>('checkout_message.continue_btn')}
          </Button>
        </div>
      }
    </div>
  );
}
