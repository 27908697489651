import {useState} from 'react';
import axios, {sessionAxios} from '@lib/axios';
import {AxiosResponse, Method} from 'axios';
import {APIRequestConfig} from '@enums/request-config';
import {useRouter} from 'next/router';
import {LocaleEnum} from '@enums/locale';

interface UseRequestInterface<D> {
  url: string;
  method: Method;
  body?: D;
  requiresAuth?: boolean;
  params?: {[key: string]: unknown};
  headers?: {[key: string]: unknown};
  onSuccess?: (data: any) => void;
  onError?: (
    errors: {message: string; field?: string}[],
    statusCode: number
  ) => void;
}
export const useRequest = (locale?: string) => {
  if (!locale) {
    const router = useRouter();
    locale = router.locale || LocaleEnum.FI;
  }
  const [errors, setErrors] = useState<{message: string; field?: string}[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const sendRequest = async <T = any, D = any>({
    url,
    method,
    body,
    params,
    onSuccess,
    onError,
    requiresAuth,
    headers,
  }: UseRequestInterface<D>) => {
    try {
      setErrors([]);
      setLoading(true);
      const response = await (requiresAuth ? sessionAxios : axios).request<
        T,
        AxiosResponse<T>,
        D
      >({
        url,
        method,
        data: body,
        params: params,
        headers: {
          [APIRequestConfig.X_LOCALE]: locale!,
          ...headers,
        },
      });
      if (onSuccess) {
        return onSuccess(response.data);
      }
      return response.data;
    } catch (err: any) {
      const statusCode = err.response?.status || 500;
      let errors = err.response?.data.errors;
      if (!err.response?.data?.error) {
        errors = err.response?.data;
      }
      let formattedErrors: {message: string; field?: string}[];
      if (errors) {
        formattedErrors = Object.keys(errors).map(key => {
          return {message: errors[key][0], field: key};
        });
        setErrors(formattedErrors);
      } else {
        formattedErrors = [
          {
            message:
              err.response?.data?.message ||
              err.response?.data?.error ||
              'Unknown request error!',
          },
        ];
        setErrors(formattedErrors);
      }
      if (onError) onError(formattedErrors, statusCode);
    } finally {
      setLoading(false);
    }
  };
  return {sendRequest, errors, loading};
};
