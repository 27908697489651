import {modalState, ModalStateInterface} from '../states/modal';
import {ModalActions, ModalActionTypes} from '../actions/modal';

export default function modalReducer(
  state: ModalStateInterface = modalState,
  action: ModalActions
) {
  switch (action.type) {
    case ModalActionTypes.TOGGLE_LOGIN_MODAL:
      return {...state, isLoginModalOpen: !state.isLoginModalOpen};
    case ModalActionTypes.TOGGLE_FORGOT_PASSWORD_MODAL:
      return {
        ...state,
        isLoginModalOpen: false,
        isForgotPasswordModalOpen: !state.isForgotPasswordModalOpen,
      };
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}
