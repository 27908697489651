import styles from './style.module.css';
import Link from '@components/Common/Link';
import {MouseEventHandler, ReactNode} from 'react';
import {LocaleEnum} from '@enums/locale';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  block?: boolean;
  size?: 'md' | 'sm' | 'lg';
  href?: string;
  onClick?: MouseEventHandler<any>;
  locale?: LocaleEnum;
  classNames?: string;
  variant?: 'button' | 'link';
  color?: 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  children: ReactNode;
}
export default function Button(props: ButtonProps) {
  const {
    children,
    type = 'button',
    block = false,
    size = 'md',
    href,
    onClick,
    locale,
    classNames = '',
    variant = 'button',
    color = 'primary',
    ...otherProps
  } = props;

  let btnClasses = '';
  switch (color) {
    case 'primary':
      btnClasses += styles.btnPrimary;
      break;
    case 'default':
      btnClasses += styles.btnDefault;
      break;
    default:
      btnClasses += '';
  }

  return variant === 'button' ? (
    <button
      onClick={onClick}
      type={type}
      className={`btn ${styles.button}  
            ${size === 'sm' ? styles.smallButton : ''} ${classNames}  ${
        block ? styles.btnBlock : ''
      }
             ${btnClasses}`}
      {...otherProps}
    >
      {children}
    </button>
  ) : (
    <Link
      href={href}
      locale={locale}
      classNames={`btn ${styles.button} 
                ${block ? styles.btnBlock : ''}
                ${btnClasses}`}
    >
      {children}
    </Link>
  );
}
