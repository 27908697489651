import {UserCartActionTypes as Actions, UserCartActions} from '../actions/';
import {UserCartStateInterface, userCartState} from '@contexts/states';

export function userCartReducer(
  state = userCartState,
  action: UserCartActions
): UserCartStateInterface {
  switch (action.type) {
    case Actions.USER_CART_FETCHED:
      const {cartItems} = action.payload;
      const itemsCount = getTotalQuantity(cartItems) || 0;
      return {...state, cartItems, itemsCount, loading: false};
    case Actions.FETCH_USER_CART:
      return {...state, loading: true};
    case Actions.ADDING_ITEM_TO_CART:
      return {...state, addItemLoading: true};
    case Actions.ITEMS_ADDED_TO_CART:
      return {
        ...state,
        itemsCount: state.itemsCount + action.payload.quantity,
        addItemLoading: false,
        loading: true,
      };
    case Actions.UPDATE_USER_ORDER:
      return {...state, updateOrderLoading: true};
    case Actions.USER_ORDER_UPDATED:
      return {...state, updateOrderLoading: false};
    case Actions.UPDATE_DELIVERY_PAYMENT_OPTIONS:
      const deliveryMethods = action.payload.delivery_methods ?? {};
      const paymentMethods = action.payload.payment_methods ?? {};
      return {...state, paymentMethods, deliveryMethods};
    case Actions.ADD_PAYMENT_PROVIDERS:
      const paymentProviders = action.payload.paymentProviders;
      return {...state, paymentProviders, updateOrderLoading: false};
    case Actions.SET_USER_DELIVERY_INFO:
      const deliveryInfo = action.payload.deliveryInfo;
      return {...state, cartItems: {...state.cartItems, ...deliveryInfo}};
    default: {
      throw new Error('Unhandled action type: ');
    }
  }
}

const getTotalQuantity = (cartItems: UserCartStateInterface['cartItems']) => {
  let total = 0;
  cartItems?.rows
    ?.filter(item => !item.order_row_id && item.type === 'product')
    .map(item => {
      total = Number(item.quantity) + total;
    });
  return total;
};
