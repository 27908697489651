import {OrderInterface} from '@interfaces/order';
import {UserCartStateInterface} from '@contexts/states';
import {PaymentProviderResponse} from '@interfaces/payment-provider';

export enum UserCartActionTypes {
  ADDING_ITEM_TO_CART,
  ITEMS_ADDED_TO_CART,
  FETCH_USER_CART,
  USER_CART_FETCHED,
  UPDATE_USER_ORDER,
  USER_ORDER_UPDATED,
  UPDATE_DELIVERY_PAYMENT_OPTIONS,
  DELETE_ORDER_ITEM,
  ADD_PAYMENT_PROVIDERS,
  USER_ORDER_SENT,
  SET_USER_DELIVERY_INFO,
}

interface AddingItem {
  type: UserCartActionTypes.ADDING_ITEM_TO_CART;
}
interface ItemsAdded {
  type: UserCartActionTypes.ITEMS_ADDED_TO_CART;
  payload: {quantity: number};
}
interface FetchUserCart {
  type: UserCartActionTypes.FETCH_USER_CART;
}

interface UserCartFetched {
  type: UserCartActionTypes.USER_CART_FETCHED;
  payload: {cartItems: UserCartStateInterface['cartItems']};
}

interface UpdateUserOrder {
  type: UserCartActionTypes.UPDATE_USER_ORDER;
}
interface UserOrderUpdated {
  type: UserCartActionTypes.USER_ORDER_UPDATED;
}

interface UpdateDeliveryAndPaymentOptions {
  type: UserCartActionTypes.UPDATE_DELIVERY_PAYMENT_OPTIONS;
  payload: {
    delivery_methods: UserCartStateInterface['deliveryMethods'];
    payment_methods: UserCartStateInterface['paymentMethods'];
  };
}

interface AddPaymentProvider {
  type: UserCartActionTypes.ADD_PAYMENT_PROVIDERS;
  payload: {
    paymentProviders: PaymentProviderResponse;
  };
}

interface SetUserDeliveryInfo {
  type: UserCartActionTypes.SET_USER_DELIVERY_INFO;
  payload: {
    deliveryInfo: Partial<{
      delivery_address: string;
      delivery_postal_code: string;
      delivery_city: string;
      delivery_country_code: string;
      delivery_method: string;
    }>;
  };
}

export type UserCartActions =
  | AddingItem
  | ItemsAdded
  | FetchUserCart
  | UserCartFetched
  | UpdateUserOrder
  | UserOrderUpdated
  | UpdateDeliveryAndPaymentOptions
  | AddPaymentProvider
  | SetUserDeliveryInfo;
