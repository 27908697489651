import {ProductInterface} from '@interfaces/product';
import {ProductDisplayAccessory} from '@contexts/actions';

interface Dimensions {
  length: null | number;
  height: null | number;
  width: null | number;
  productLengths: number[];
  productHeights: number[];
  productWidths: number[];
  productDimensionsLoaded: boolean;
  product_dimensions: {
    sales_unit_length: number;
    sales_unit_height: number;
    sales_unit_width: number;
  }[];
}
export interface ProductDisplayStateInterface {
  load_product_prices: boolean;
  product?: Pick<ProductInterface, 'uid' | 'id'> & {
    unit_price_vat_included: number;
    discount_unit_price_vat_excluded: number;
    discount_unit_price_vat_included: number;
    vat_percentage: number;
    vat_text: string;
  };
  accessories: Record<string, ProductDisplayAccessory[]>;
  linked_products: Record<string, ProductDisplayAccessory>;
  type: 'product' | 'product_accessories';
  dimensions: Dimensions;
  key_value_pairs: string;
  quantity: number;
  total_discount_price_vat_excluded: number;
  total_discount_price_vat_included: number;
  total_price_vat_included: number;
  total_price_vat_excluded: number;
  customer_visible_comment: string;
}

export const productDisplayState: ProductDisplayStateInterface = {
  type: 'product',
  load_product_prices: false,
  accessories: {},
  linked_products: {},
  total_price_vat_included: 0,
  total_price_vat_excluded: 0,
  total_discount_price_vat_excluded: 0,
  total_discount_price_vat_included: 0,
  quantity: 1,
  key_value_pairs: JSON.stringify({}),
  dimensions: {
    length: null,
    height: null,
    width: null,
    productDimensionsLoaded: false,
    product_dimensions: [],
    productLengths: [],
    productHeights: [],
    productWidths: [],
  },
  customer_visible_comment: '',
};
