// add bootstrap css
import 'bootstrap/dist/css/bootstrap.css';
import 'nprogress/nprogress.css';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-18-image-lightbox/style.css';
import '@styles/nprogress.css';
import '@styles/globals.css';
import '@styles/cms-items.css';
import '@styles/content-box.css';
import '@styles/home.css';

import '@styles/productGroup.css';

import {SessionProvider} from 'next-auth/react';
import {GTM_ID, pageview} from '@lib/gtm';
import Router, {useRouter} from 'next/router';
import NProgress from 'nprogress';

import {
  ModalContextProvider,
  UserCartContextProvider,
} from '@contexts/providers';
import {appWithTranslation} from 'next-i18next';
import {ToastContainer} from 'react-toastify';
import {AppProps} from 'next/app';
import {useEffect} from 'react';
import {setCookies} from 'cookies-next';
import {Session} from 'next-auth';
import Script from 'next/script';
import {LocaleEnum} from '@enums/locale';
const cookieLangMap = {
  [LocaleEnum.FI]: 'FI',
  [LocaleEnum.EN]: 'EN',
  [LocaleEnum.SE]: 'SV',
};

function MyApp({
  Component,
  pageProps: {session, ...pageProps},
}: AppProps<{session: Session}>) {
  const router = useRouter();
  const {locale} = router;

  NProgress.configure({showSpinner: false});
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });

  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });

  useEffect(() => {
    setCookies('NEXT_LOCALE', router.locale); // set lang cookie so every axios request could have same lang
    return () => {};
  }, [router.locale]);

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);
  return (
    <SessionProvider session={session}>
      <ModalContextProvider>
        <ToastContainer
          theme={'colored'}
          pauseOnHover={false}
          autoClose={3000}
        />
        <UserCartContextProvider>
          {/* cookie popup */}
          <Script
            id="CookieConsent"
            src="https://policy.app.cookieinformation.com/uc.js"
            data-culture={
              cookieLangMap[(locale || LocaleEnum.FI) as LocaleEnum]
            }
            type="text/javascript"
          ></Script>
          {/* End cookie popup */}
          {/*Google Tag Manager */}
          <Script
            id="google-tag-manager"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_ID}');`,
            }}
          />
          {/*End Google Tag Manager*/}
          <Component {...pageProps} />
        </UserCartContextProvider>
      </ModalContextProvider>
    </SessionProvider>
  );
}

export default appWithTranslation<never>(MyApp);
