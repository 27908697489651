import {createContext, useReducer} from 'react';

import {
  productDisplayState,
  ProductDisplayStateInterface,
} from '@contexts/states';
import {
  LinkedProductChanged,
  ProductDisplayActionTypes as ActionTypes,
  SetProduct,
  SetProductPrices,
} from '../actions/product-display';
import {useRequest} from '@hooks/use-request';
import {useRouter} from 'next/router';
import {ContextProps} from '@contexts/index';
import {productDisplayReducer} from '@contexts/reducers';

interface ContextInterface {
  state: ProductDisplayStateInterface;
  setProductNull: () => void;
  setProduct: (payload: SetProduct['payload']) => void;
  setQuantity: (quantity: number) => void;
  loadProductPrices: () => void;
  onAccessorySelected: (payload: LinkedProductChanged['payload']) => void;
  setProductPrices: (payload: SetProductPrices['payload']) => void;
  getSalesUnitLength: (queryLength?: number) => void;
  getSalesUnitHeight: (queryHeight?: number) => void;
  getSalesUnitWidth: (queryWidth?: number) => void;
  setDimension: (
    payload: Partial<Record<'length' | 'width' | 'height', number>>
  ) => void;
  getProductDimensions: (id: number) => void;
  getProductByDimension: (id: number) => void;
  getProductPrices: () => void;
  setKeyValuePairs: (payload: Record<string, string>) => void;
}
export const ProductDisplayContext = createContext<ContextInterface>(
  {} as unknown as ContextInterface
);

export function ProductDisplayContextProvider(props: ContextProps) {
  const {children} = props;
  const {locale} = useRouter();
  const {sendRequest} = useRequest(locale);
  const [state, dispatch] = useReducer(
    productDisplayReducer,
    productDisplayState
  );
  const {
    dimensions: {width, length, height, product_dimensions},
    quantity,
    product,
  } = state as ProductDisplayStateInterface;

  const setProductNull = () => {
    dispatch({type: ActionTypes.GET_PRODUCT});
  };

  const setQuantity = (quantity: number) => {
    dispatch({type: ActionTypes.SET_PRODUCT_QUANTITY, quantity});
  };

  const onAccessorySelected = (payload: LinkedProductChanged['payload']) => {
    dispatch({type: ActionTypes.LINKED_PRODUCT_CHANGED, payload: payload});
  };

  const setProduct = (payload: SetProduct['payload']) => {
    dispatch({type: ActionTypes.PRODUCT_FETCHED, payload});
  };

  const setProductPrices = (payload: SetProductPrices['payload']) => {
    dispatch({type: ActionTypes.SET_PRODUCT_PRICES, payload});
  };

  const loadProductPrices = () => {
    dispatch({type: ActionTypes.LOAD_PRODUCT_PRICES});
  };

  const clearProduct = () => {
    if (product) {
      setProductNull();
    }
  };

  const sortASC = (a: number, b: number) => a - b;
  const getProductDimensions = async (id: number) => {
    await sendRequest({
      url: `store/productgroupdimensions/${id}?dimension`,
      method: 'get',
      onSuccess: (response: any) => {
        const product_dimensions = response.data
          .dimensions as ProductDisplayStateInterface['dimensions']['product_dimensions'];
        dispatch({
          type: ActionTypes.SET_PRODUCT_DIMENSIONS,
          payload: {product_dimensions, productDimensionsLoaded: true},
        });
      },
    });
  };

  const getSalesUnitLength = async (queryLength?: number) => {
    let previousLength = queryLength || length;
    clearProduct();
    dispatch({
      type: ActionTypes.SET_PRODUCT_DIMENSIONS,
      payload: {length: null, height: null, width: null},
    });
    const productLengths = product_dimensions
      .filter(dim => !!dim.sales_unit_length)
      .map(dim => Number(dim.sales_unit_length))
      .sort(sortASC)
      .filter((x, i, a) => a.indexOf(x) === i);
    if (!previousLength || !productLengths.includes(previousLength)) {
      previousLength = productLengths[0];
    }
    dispatch({
      type: ActionTypes.SET_PRODUCT_DIMENSIONS,
      payload: {
        productLengths,
        length: Number(previousLength),
      },
    });
  };

  const getSalesUnitHeight = async (queryHeight?: number) => {
    clearProduct();
    let previousHeight = queryHeight || height;
    dispatch({
      type: ActionTypes.SET_PRODUCT_DIMENSIONS,
      payload: {height: null, width: null},
    });
    const productHeights = product_dimensions
      .filter(
        dim =>
          !!dim.sales_unit_height && Number(dim.sales_unit_length) === length
      )
      .map(item => Number(item.sales_unit_height))
      .sort(sortASC)
      .filter((x, i, a) => a.indexOf(x) === i);
    if (!previousHeight || !productHeights.includes(previousHeight)) {
      previousHeight = productHeights[0];
    }

    dispatch({
      type: ActionTypes.SET_PRODUCT_DIMENSIONS,
      payload: {height: previousHeight, productHeights},
    });
  };

  const getSalesUnitWidth = async (queryWidth?: number) => {
    let previousWidth = queryWidth || width;
    dispatch({
      type: ActionTypes.SET_PRODUCT_DIMENSIONS,
      payload: {width: null},
    });
    const productWidths = product_dimensions
      .filter(
        dim =>
          !!dim.sales_unit_width &&
          Number(dim.sales_unit_length) === length &&
          Number(dim.sales_unit_height) === height
      )
      .map(dim => Number(dim.sales_unit_width))
      .sort(sortASC)
      .filter((x, i, a) => a.indexOf(x) === i);
    if (!previousWidth || !productWidths.includes(previousWidth)) {
      previousWidth = productWidths[0];
    }
    dispatch({
      type: ActionTypes.SET_PRODUCT_DIMENSIONS,
      payload: {productWidths, width: previousWidth},
    });
  };

  const setKeyValuePairs = async (payload: Record<string, string>) => {
    dispatch({type: ActionTypes.SET_KEY_VALUE_PAIRS, payload});
  };

  const getProductByDimension = async (id: number) => {
    clearProduct();
    await sendRequest({
      url: `store/productgroupproductsbydimensions/${id}?dimension=SalesUnitWidth&SalesUnitLength=${length}&SalesUnitHeight=${height}&SalesUnitWidth=${width}`,
      method: 'get',
      onSuccess: (response: any) => {
        const resProduct = response.data.products[0] as SetProduct['payload'];
        setProduct(resProduct);
      },
    });
  };

  const getProductPrices = async () => {
    const linked_products = Object.keys(state.linked_products).map(
      product_group_id => {
        const accessories = state.linked_products[product_group_id];
        return {
          product_id: accessories.id,
          quantity,
          type: 'product_accessories',
        };
      }
    );

    await sendRequest({
      url: 'store/productprice',
      method: 'post',
      body: {product_id: product?.id, quantity, linked_products},
      requiresAuth: true,
      onSuccess: (response: any) => {
        setProductPrices(response.data as SetProductPrices['payload']);
      },
    });
  };

  return (
    <ProductDisplayContext.Provider
      value={{
        state,
        setProductNull,
        setQuantity,
        setProduct,
        onAccessorySelected,
        setProductPrices,
        loadProductPrices,
        getSalesUnitLength,
        getSalesUnitHeight,
        getSalesUnitWidth,
        getProductByDimension,
        getProductDimensions,
        getProductPrices,
        setKeyValuePairs,
        setDimension: (
          payload: Partial<Record<'length' | 'width' | 'height', number>>
        ) =>
          dispatch({
            type: ActionTypes.SET_PRODUCT_DIMENSIONS,
            payload,
          }),
      }}
    >
      {children}
    </ProductDisplayContext.Provider>
  );
}
